/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import "./NavBar.scss";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../Hooks/UseWindowSize";
import ProductsList from "../BurgerMenuModal/BurgerMenuModal";
import ProductsModal from "../ProductsModal/ProductsModal";
import { Button, Popover, PopoverBody } from "reactstrap";

import g2aMenu from "../../pages/G2A/Main/images/g2a_menu.webp";


const navigationPositions = [ // Optional args: additionalDesktopStyle (object) / additionalMobileStyle (object) / hoverEffect (bool)
  { mobileContent: "Produkty", desktopContent: "Produkty", id: 1 },
  { mobileContent: "Znajdź sklep", desktopContent: "Znajdź sklep", id: 2 },
  { mobileContent: "Instrukcja pieczenia", desktopContent: "Instrukcja pieczenia", id: 3 },
  { mobileContent: "Dowiedz się więcej", desktopContent: "Dowiedz się więcej", id: 4 },
  { mobileContent: "Kontakt", desktopContent: "Kontakt", id: 5 },
  // { mobileContent: "Gra", desktopContent: "Gra", id: 6 },
  { mobileContent: <img alt="g2a" src={g2aMenu} style={{ margin: "0px", padding: "0px"}}/>, desktopContent: <img src={g2aMenu} style={{ zIndex: "8"}}/>, id: 7, additionalDesktopStyle: {backgroundColor: "#01abe8"}, stopHover: true },
];

const NavBar = ({ isMobileProductListOpen, onClose }) => {
  const [open, setOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showMobileProductsList, setShowMobileProductsList] = useState(false);
  const size = useWindowSize();
  const { width } = size;
  const history = useHistory();

  useEffect(() => {
    if (isMobileProductListOpen) {
      setPopoverOpen(true);
      setShowMobileProductsList(true);
    }
  }, [isMobileProductListOpen, onClose]);

  const toggle = () => {
    if (popoverOpen) {
      setShowMobileProductsList(false);
      onClose();
      document.body.classList.remove("modal-open");
    }
    setPopoverOpen(!popoverOpen);
  };

  const navigationHandler = (id, device) => {
    if (id === 1 && device === "mobile") {
      setShowMobileProductsList(true);
    } else if (id === 5) {
      document.getElementById("footer").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      var newPath = generateHref(id)
      history.push({
        pathname: newPath,
      });
      window.scrollTo(0, 0);
    }
  };

  const logoGuseppeHandler = () => {
    history.push({
      pathname: "/",
    });
    window.scrollTo(0, 0);
  };

  const openProductsHandler = (id) => {
    if (id === 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const generateHref = (id) => {
    if (id === 2) {
      return "/znajdz-sklep";
    }
    if (id === 3) {
      return "/jak-upiec";
    }
    if (id === 4) {
      return "/dowiedz-sie-wiecej";
    }
    if (id === 5) {
      return "/#footer";
    }
    if (id === 6) {
      return "/gra";
    }
    if (id === 7) {
      return "/promocja-g2a";
    }
    return "/";
  };

  return (
    <header
      className="header"
      style={
        history.location.pathname === "/pizza-salami"
          ? { position: "absolute" }
          : null
      }
    >
      <div
        className="logoContainer"
        onMouseEnter={() => {
          setOpen(false);
        }}
      >
        <div>
          <a
            href="https://www.oetker.pl/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "inline" }}
          >
            <img
              className="logoDrOetker"
              alt="DrOetker"
              src={`${process.env.PUBLIC_URL}/images/logo-dr-oetker.png`}
            />
          </a>
        </div>

        <img
          src={`${process.env.PUBLIC_URL}/images/logo-guseppe.png`}
          alt="Logo Guseppe"
          className="logoGuseppe"
          onClick={logoGuseppeHandler}
        />
      </div>
      <div
        className="navigation"
        onMouseEnter={() => {
          setOpen(false);
        }}
      >
        {width > 1200 ? (
          <ul className="navigationList">
            {navigationPositions.map((position) => {
              if (width > 1200) {
                return (
                  // eslint-disable-next-line
                  <a
                    key={position.id}
                    className="navigationElement"
                    onClick={() => navigationHandler(position.id, "desktop")}
                    onMouseEnter={() => openProductsHandler(position.id)}
                    href={generateHref(position.id)}
                    style={position.additionalDesktopStyle}
                  >
                    <li style={{zIndex: "10"}}>{position.desktopContent}</li>
                    {(!position.stopHover ? <div
                      alt=""
                      className="navigationElementImage"
                      style={
                        open && position.id === 1
                          ? { width: "100%", border: "1px solid #a71814" }
                          : null
                      }
                    /> : null)}
                  </a>
                );
              }
            })}
          </ul>
        ) : null}
        <div className="socialMedia">
          <a
            className="FacebookButton"
            href="https://www.facebook.com/PizzaGuseppe/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/facebook.png`}
              alt="Facebook"
            />
          </a>
          <a
            href="http://m.me/PizzaGuseppe/"
            target="_blank"
            rel="noopener noreferrer"
            className="MessangerButton"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/messanger.png`}
              alt="Messenger"
            />
          </a>
          <a
            className="InstagramButton"
            href="https://www.instagram.com/pizzaguseppe/?hl=pl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/instagram.png`}
              alt="Instagram"
            />
          </a>
          <a
            className="YoutubeButton"
            href="https://www.youtube.com/channel/UC60DjNZjVmFye8VKmt2NZPw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/youtube.png`}
              alt="Youtube"
            />
          </a>
        </div>
        {width > 1200 ? null : (
          <div className="menu">
            <Button
              id="Popover1"
              type="button"
              style={{ backgroundColor: "transparent" }}
              onClick={toggle}
            >
              <div
                className="burgerMenu"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/tlo-menu.png)`,
                }}
              >
                {popoverOpen ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/BurgerMenuModal/x.png`}
                    alt="X"
                  />
                ) : (
                  <>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/burger-menu-line.png`}
                      alt="Linia"
                    />

                    <img
                      src={`${process.env.PUBLIC_URL}/images/burger-menu-line.png`}
                      alt="Linia"
                    />

                    <img
                      src={`${process.env.PUBLIC_URL}/images/burger-menu-line.png`}
                      alt="Linia"
                    />
                  </>
                )}
              </div>
            </Button>
            {showMobileProductsList ? (
              <ProductsList setPopoverOpen={setPopoverOpen} />
            ) : (
              <Popover
                placement="bottom"
                isOpen={popoverOpen}
                target="Popover1"
                toggle={toggle}
                style={{ backgroundColor: "#a71814" }}
              >
                <PopoverBody>
                  <ul className="burgerNavigation">
                    {navigationPositions.map((position) => {
                      return (
                        <li
                          onClick={() =>
                            navigationHandler(position.id, "mobile")
                          }
                          key={position.id}
                          style={{ ...position.additionalMobileStyle, marginBottom: "5px" }}
                        >
                          {position.mobileContent}
                        </li>
                      );
                    })}
                  </ul>
                </PopoverBody>
              </Popover>
            )}
          </div>
        )}
      </div>
      {width > 1200 ? <ProductsModal open={open} setOpen={setOpen} /> : null}
    </header>
  );
};

export default NavBar;
